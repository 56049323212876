export const isBrowser = typeof window !== "undefined";

export function convertToSlug(Text) {
  if (Array.isArray(Text)) {
    if (
      Text.some((value) => {
        return typeof value == "object";
      })
    ) {
      return Text[0]?.props?.text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }
    return Text[0]
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}
export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
export function removeDateFromString(str) {
  const parts = str.split(":");

  const dateRegex = /^[A-Za-z]+\.?\s+\d{1,2},\s+\d{4}/; // Date format regex (e.g., "Dec. 11, 2023")
  if (parts.length > 1 && dateRegex.test(parts[0])) {
    return parts.slice(1).join(":").trim();
  }

  return str;
}
